@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    font-family: 'Inter', sans-serif;
}

/* CustomCursor Start */

body {
    @apply bg-[#fff]
  }
h2 {
  @apply text-neutral-10 font-semibold text-3xl
}
h3 {
  @apply text-neutral-9 font-medium text-2xl pt-5
}
p {
  @apply text-neutral-8 font-normal text-base
}
.textblock {
  @apply flex flex-col gap-8
}
.quoteblock {
  @apply flex flex-col gap-4 border-l border-neutral-5 pl-3
}
.quoteblock p {
  @apply italic
}

  /* Image Overlay Start */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  .overlay img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .image-alt {
    @apply text-base font-medium pt-2 text-neutral-7;
  }
  /* Image Overlay End */

  /* Home Styles Start */
  @keyframes scroll-down-cta {
    0% {
      transform: translateY(-40px);
      opacity: 0;
    }
    50% {
      transform: translateY(0px);
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }

  }
  .scroll-down-cta {
    animation: scroll-down-cta 4s ease-in-out 0s infinite normal;
  }


  @keyframes fadeIn {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }

  }

  .fade-in-animation {
    animation: fadeIn .5s ease-in-out forwards;
  }


  @keyframes navFade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }

  }

  .nav-fade-animation {
    animation: navFade .5s ease-in forwards;
  }
  /* Home Styles End */

  .particles-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: url('../assets/test.svg');
    background-size: cover ;
    background-attachment: fixed;
  }
#bgrnd {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url('../assets/test.svg');
  /* background-size: 100%; */
  background-repeat: no-repeat;
  background-position:fixed;
}
@font-face {
  font-family: 'autoglyphs';
  src: url('../../public/autoglyphs-400.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
#autoglyph {
  display: flex;
  font-family: autoglyphs,monospace;
  line-height: 1;
  white-space: pre-wrap;
  word-break: break-all;
  width: inherit;
  /* background-image: url("../assets/noise.png"); */
}
#reactglyph {
  display: flex;
  font-family: autoglyphs,monospace;
  line-height: 1;
  white-space: pre-wrap;
  word-break: break-all;
  /* background-image: url("../assets/noise.png"); */
}
